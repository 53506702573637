import { createStore } from 'vuex'
// import createPersistedState from "vuex-persistedstate";
import authModule from './modules/auth'
import dashboardTabs from './modules/dashboardTabs'
import serverPermissions from './modules/serverPermissions'

const store = createStore({
  state () {
    return {
      appName: process.env.VUE_APP_NAME,
      reload: false
    }
  },
  mutations: {
    setReload(state, payload) {
      state.reload = payload;
    },
  },
  actions: {

  },
  getters: {
    getAppName: (state) => state.appName,
    getReload: (state) => state.reload,
  },

  modules: {
    auth: authModule,
    dashboardTabs,
    serverPermissions,
  },

  plugins: [
    // createPersistedState({
    //   key: 'dashboardTabMenus',
    //   paths: ['dashboardTabs']
    // })
  ],
})

export default store
<template>
  <div>
    <header class="header_area  fixed-top">
      <div class="container-fluid">
          <div class="row">
              <div class="col-md-12">
                  <div class="header_main_wrap">
                      <div class="header_left">
                        <div class="logo mt_5">
                          <router-link :to="{name: 'dashboard'}">
<!--                            <img src="@/assets/media/images/logo.png" alt="">-->
                          </router-link>
                        </div>
                      </div>
                      <div class="header_right_menu">
                        <ul>
                            <!-- <li>
                                <div class="header_list_btn header_search_area">
                                    <div class="btn-group">
                                        <button type="button" class="btn btn-secondary dropdown-toggle no_arrow" data-bs-toggle="dropdown" aria-expanded="false">
                                            <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M7 1a6 6 0 0 1 4.714 9.713L16 15l-1 1-4.287-4.286A6 6 0 1 1 7 1zm0 1.5a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9z"></path></svg>
                                        </button>
                                        <ul class="dropdown-menu dropdown-menu-end">
                                            <div class="header_search_inner">
                                                <div class="header_search_input">
                                                    <input type="text" placeholder="Enter a search word.">
                                                    <button><svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M7 1a6 6 0 0 1 4.714 9.713L16 15l-1 1-4.287-4.286A6 6 0 1 1 7 1zm0 1.5a4.5 4.5 0 1 0 0 9 4.5 4.5 0 0 0 0-9z"></path></svg></button>
                                                </div>
                                                <div class="no_search_history">
                                                    <div class="inner">
                                                        <svg viewBox="0 0 28 37" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 .251)"><path fill="currentColor" transform="rotate(180 14 18.375)" style="fill:#e4e8ee" d="M0 0v28l9 9h19V0H0z" id="빼기_164"></path><path transform="translate(19)" style="opacity:.6" d="M0 9h9L0 0z" fill="#748095" id="패스_207011"></path><path transform="translate(5 14.767)" style="opacity:.6" d="M0 0h15.333v1.245H0z" fill="#748095" id="사각형_144341"></path><path transform="translate(5 17.257)" style="opacity:.6" d="M0 0h15.333v1.245H0z" fill="#748095" id="사각형_144342"></path><path transform="translate(5 12.276)" style="opacity:.6" d="M0 0h6.226v1.245H0z" fill="#748095" id="사각형_144343"></path></g></svg>
                                                        <p>There is no recent search history.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </ul>
                                    </div>
                                </div>
                            </li> -->
                            <li class="header_user pr_0">
                                <div class="header_list_btn">
                                    <div class="btn-group dropdown-center">
                                        <button type="button" class="btn btn-secondary dropdown-toggle no_arrow" data-bs-toggle="dropdown" aria-expanded="false">
                                            <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path clip-rule="evenodd" d="M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10Zm3-14a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm1.368 10c.342 0 .632-.259.632-.6 0-2.982-2.239-5.4-5-5.4s-5 2.418-5 5.4c0 .341.29.6.632.6h8.736Z" fill-rule="evenodd" fill="currentColor"></path></svg>
                                        </button>
                                        <ul class="dropdown-menu dropdown-menu-end width_268p">
                                            <li>
                                              <router-link :to="{ name: 'manage_account', params: { slug: 'my-account' } }">
                                                <button class="dropdown-item" type="button">My Account</button>
                                              </router-link>
                                            </li>
                                            <!-- <li>
                                              <router-link :to="{ name: 'manage_account', params: { slug: 'security-credential' } }">
                                                <button class="dropdown-item" type="button">Security Credential</button>
                                              </router-link>
                                            </li>
                                            <li>
                                              <router-link :to="{ name: 'manage_account', params: { slug: 'my-coupon' } }">
                                                <button class="dropdown-item" type="button">My Coupon</button>
                                              </router-link>
                                            </li> -->
                                            <li @click.prevent="logout">
                                                <button class="dropdown-item logout_btn" type="button">Log out</button>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <!-- <li class="service_portal_link right_menu_icon">
                                <a href="#">
                                    <span> Service Portal </span>
                                    <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
                                        <path fill="currentColor" transform="rotate(45 323.235 -711.954)" style="fill:none;stroke-linecap:round;stroke-width:1.4px" d="M608 26.036V14.379" stroke="currentColor"></path>
                                        <path fill="currentColor" transform="translate(-605.861 -12.365)" style="stroke-linejoin:round;fill:none;stroke-linecap:round;stroke-width:1.4px" d="M610.861 15.365h6v6" stroke="currentColor"></path>
                                    </svg>
                                </a>
                            </li> -->
                        </ul>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </header>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import router from "@/routes";

export default {
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE,
    }
  },
  computed: {
    ...mapGetters({
      customer: 'auth/getUserInfo',
      services: 'auth/getCustomerServices',
    }),
  },

  methods: {
    reload(name){
      if(this.$route.name === name){
        this.$store.commit('setReload', true)
      }
    },
    logout() {
      this.axios.post('/logout').finally(() => {
        this.$store.dispatch('auth/logout')
        this.$router.push({name: 'login'})
      })
    },
  },
  beforeUnmount() {
  },
}
</script>

<style scoped>

</style>
const STORAGE_KEY = 'tabsModule';

const loadState = () => {
    try {
        const serializedState = localStorage.getItem(STORAGE_KEY);
        if (serializedState === null) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem(STORAGE_KEY, serializedState);
    } catch (err) {
        // Ignore write errors
    }
};

const persistedState = loadState();

export default {
    namespaced: true,
    state: persistedState || {
        tabs: [{ id: 1, title: 'Dashboard', route: {name: 'dashboard'} }],
        activeTabId: 1,
        nextTabId: 2,
    },
    mutations: {
        ADD_TAB(state, { title, route }) {
            if (!route || typeof route !== 'object') {
                throw new Error('Invalid route provided');
            }
            const newTab = {
                id: state.nextTabId,
                title,
                route,
            };
            state.tabs.push(newTab);
            state.activeTabId = newTab.id;
            state.nextTabId++;
            saveState(state);
        },
        CLOSE_TAB(state, tabId) {
            const index = state.tabs.findIndex(tab => tab.id === tabId);
            if (index !== -1 && state.tabs.length > 1) {
                state.tabs.splice(index, 1);
                if (state.activeTabId === tabId) {
                    state.activeTabId = state.tabs[Math.max(0, index - 1)].id;
                }
            }
            saveState(state);
        },
        SET_ACTIVE_TAB(state, tabId) {
            state.activeTabId = tabId;
            saveState(state);
        },
        UPDATE_TAB_ROUTE(state, { route }) {
            let res = state.tabs.find(item => item.id === route.id);
            if (!res) {
                state.tabs.push(route);
            } else {
                Object.assign(res, route);
            }
            saveState(state);
        },
        SET_TAB_TITLE(state, { id, title }) {
            const tab = state.tabs.find(tab => tab.id === id);
            if (tab) {
                tab.title = title;
            }
            saveState(state);
        },
    },
    actions: {
        addTab({ commit }, route = { name: 'dashboard' }) {
            commit('ADD_TAB', { title: 'Dashboard', route });
        },
        closeTab({ commit, state, dispatch }, tabId) {
            commit('CLOSE_TAB', tabId);
            if (state.activeTabId === tabId) {
                const newActiveTab = state.tabs[0];
                dispatch('setActiveTab', newActiveTab.id);
            }
        },
        setActiveTab({ commit }, tabId) {
            commit('SET_ACTIVE_TAB', tabId);
        },
        updateTabRoute({ commit }, route) {
            const activeRoute = route;
            commit('UPDATE_TAB_ROUTE', { id: route.id, route: activeRoute });
            commit('SET_TAB_TITLE', { id: route.id, title: activeRoute?.route?.name || 'New Tab' });
        },
    },
    getters: {
        allTabs: state => state.tabs,
        activeTab: state => state.tabs.find(tab => tab.id === state.activeTabId),
    }
};
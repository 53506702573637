import moment from 'moment'

export default {
    methods: {
        getAllQueryParams() {
            const params = new URLSearchParams(window.location.search);

            return Array.from(params.keys()).reduce(
                (acc, val) => ({ ...acc, [val]: params.get(val) }),
                {}
            );
        },
        dateFormat(date, format){
            if(date){
                return moment(date).format(format)
            }
            return null;
        },
        showSuccessMsg(msg) {
            this.$swal.fire({
                position: 'top-right',
                icon: 'success',
                toast: true,
                title: msg,
                showConfirmButton: false,
                timer: 1500
            })
        },
        showFailMsg(msg, timer = null) {
            this.$swal.fire({
                position: 'top-right',
                icon: 'error',
                toast: true,
                title: msg,
                showConfirmButton: false,
                timer: timer ?? 1500
            })
        },
        sidebarToggler(){
            let collapseMenu = document.querySelector('.collapse_bar');
            let wrapper = document.querySelector('#main_wrap');
            let leftNav = document.querySelector('#left_nav');
            
            /*collapseMenu.addEventListener('click' , function() {
                wrapper.classList.toggle('left_menu_toggler');
                this.classList.toggle('collapse_icon');
            })

            leftNav.addEventListener('click' , function() {

                if(wrapper.classList.contains('left_menu_toggler')){
                    wrapper.classList.remove('left_menu_toggler');
                }
            })*/
        },
        formatPrice(price) {
            return new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW' }).format(price);
        },
    }
}
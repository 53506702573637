<template>
    <template v-if="!['login','register','email-verification','forgot-password','reset-password'].includes($route.name)">
        <div id="main_wrap"  v-show="loaded">
            <header-component />
            <sidebar-component />
            <div id="content_wrap">
                <div class="container-fluid">
                    <router-view v-if="loaded"></router-view>
                </div>
            </div>
        </div>
    </template>
    <router-view v-else></router-view>
</template>

<script>
import HeaderComponent from './components/shared/HeaderComponent';
import SidebarComponent from './components/shared/SidebarComponent.vue';
// import store from "@/store";
import {mapGetters} from "vuex";
import {ABILITY_TOKEN, useAbility} from "@casl/vue";
import {AbilityBuilder, createMongoAbility} from "@casl/ability";

export default {
  inject: {
    ability: { from: ABILITY_TOKEN }
  },
    components: {HeaderComponent, SidebarComponent},
    name: 'App',
  setup() {
    const { can } = useAbility();

    return {
      can
    };
  },
    data() {
        return {
          loaded: false,
        }
    },
    watch: {
        $route(){
            if(window.outerWidth <= 768){
                // window.$("#wrapper").addClass('vertical_collpsed')
                // window.$(".mobile_overlay").removeClass('open')
            }
        },
    },
  computed: {
    containerClass() {
      return this.$route.name === 'dashboard' ? 'container' : 'ticket_common_page';
    },
    ...mapGetters({
      permission: "auth/permissionList",
      customer: 'auth/getUserInfo',
    })
  },
  async mounted() {
    try {
      const { data } = await this.axios('/customer');

      // Await each dispatch to ensure it's fully processed before moving to the next
      await this.$store.dispatch('auth/setCustomer', data);
      await this.$store.dispatch('auth/customerInfo', data);
      // await this.$store.dispatch('auth/customerServices');
      // await this.$store.dispatch('auth/getPermissions');
      // await this.$store.dispatch('serverPermissions/servicePermissionList');

      // Use a setTimeout for permission checks after dispatches are complete

      const { can, rules } = new AbilityBuilder(createMongoAbility);
      can(this.permission);
      this.ability.update(rules);

    } catch (error) {
      // Handle error by logging out and redirecting to login
      if(!['register','email-verification','forgot-password','reset-password'].includes(this.$route.name)){
        this.$store.dispatch('auth/logout');
        this.$router.push({ name: 'login' });
      }
      
    } finally {
      // Mark the component as loaded
      this.loaded = true;
    }

    // Call sidebar toggler
    this.sidebarToggler();
  }
}
</script>

<style>

</style>
import axios from "@/util/axios";
import Cookies from 'js-cookie'
export default {
  namespaced: true,
  state () {
    return {
      customer: null,
      customerInfo: null,
      customerServices: [],
      permissions: [],
    }
  },
  mutations: {
    setUser(state, payload) {
      state.customer = payload;
    },
    setUserInfo(state, payload) {
      state.customerInfo = payload;
    },
    setCustomerServices(state, payload) {
      state.customerServices = payload;
    },
    setAbility(state, payload) {
      state.permissions = payload
    },
  },
  actions: {
    login({commit}, payload) {
      return new Promise((resolve, reject) => {
        axios.post('/login', payload).then((response) => {
          let customer = response.data.data;
          commit('setUser', customer);
          Cookies.set('auth_token', customer.token)
          // localStorage.setItem('customer', JSON.stringify(customer));

          axios.defaults.headers.common['Authorization'] = `Bearer ${customer.token}`;

          resolve(customer);
        }).catch((error) => {
          reject(error.response.data)
        });
      });
    },
    logout({commit}) {
      commit('setUser', null);
      Cookies.remove('auth_token')
      // localStorage.removeItem('customer');
      axios.defaults.headers.common['Authorization'] = null;
    },
    setCustomer({commit}, payload) {
      commit('setUser', payload);
    },
    customerInfo({commit}, payload) {
      commit('setUserInfo', payload);
    },
    customerServices({commit}) {
      axios.get('/customer-cloud-services').then(({ data }) => {
        commit('setCustomerServices', data.data);
      })
    },
    async getPermissions({commit}){
      await axios.get('/customer-abilities')
          .then(({ data }) => {
            commit('setAbility', data)
          })
    }
  },
  getters: {
    getUser: (state) => state.customer,
    getUserInfo: (state) => state.customerInfo,
    getCustomerServices: (state) => state.customerServices,
    permissionList: (state) => state.permissions
  }
}
<template>
  <div class="left_nav_wrap">
    <div class="collapse_bar">
        <svg class="collapsed" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg"><g style="stroke:#d2d3d6;fill:#fff"><circle style="stroke:none" stroke="none" r="18" cy="18" cx="18"></circle><circle style="fill:none" r="17.5" cy="18" cx="18"></circle></g><path fill="currentColor" transform="translate(16.501 13)" style="stroke:currentColor;stroke-linecap:round;stroke-miterlimit:10;stroke-width:2px;fill:none" d="m0 10 4-5-4-5"></path><path fill="currentColor" transform="translate(10 18)" style="stroke:currentColor;stroke-linecap:round;stroke-miterlimit:10;stroke-width:2px;fill:none" d="M9 0H0"></path><path fill="currentColor" transform="translate(23.501 12.5)" style="stroke:currentColor;stroke-linecap:round;stroke-miterlimit:10;stroke-width:2px;fill:none" d="M0 0v11"></path></svg>
        <svg class=" no_collapse" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg"><g style="stroke:#d2d3d6;fill:#fff"><circle style="stroke:none" stroke="none" r="18" cy="18" cx="18"></circle><circle style="fill:none" r="17.5" cy="18" cx="18"></circle></g><path fill="currentColor" transform="translate(15.5 13)" style="stroke:currentColor;stroke-linecap:round;stroke-miterlimit:10;stroke-width:2px;fill:none" d="M4 10 0 5l4-5"></path><path fill="currentColor" transform="translate(17 18)" style="stroke:currentColor;stroke-linecap:round;stroke-miterlimit:10;stroke-width:2px;fill:none" d="M0 0h9"></path><path fill="currentColor" transform="translate(12.5 12.5)" style="stroke:currentColor;stroke-linecap:round;stroke-miterlimit:10;stroke-width:2px;fill:none" d="M0 0v11"></path></svg>
    </div>
    <div id="left_nav">
        <ul class="left_nav_list">
            <li :class="{ 'active' : $route.name === 'dashboard' }">
                <div class="nav_list_header no_arrow border_top_none">
                  <router-link to="/"> 
                    <span class="icons">
                        <i class="material-symbols-outlined">
                            grid_view
                        </i>
                    </span>
                    <span class="text">
                        Dashboard
                    </span>
                  </router-link>
                </div>
            </li>
            <li :class="{ 'active' : $route.name === 'all_tickets' }">
                <div class="nav_list_header no_arrow border_top_none">
                  <router-link to="/tickets">
                    <span class="icons">
                        <i class="material-symbols-outlined">
                            table_chart
                        </i>
                    </span>
                    <span class="text">
                        Tickets
                    </span>
                  </router-link>
                </div>
            </li>

            <!-- <li>
                <div class="nav_list_header no_arrow">
                    <a href="#"> 
                        <span class="icons">
                            <i class="material-symbols-outlined">
                                south_america
                            </i>
                        </span>
                        <span  class="text">
                            Region
                        </span>
                    </a>
                </div>
            </li> -->
        </ul>
    </div>
</div>
</template>

<script>

import {useAbility} from "@casl/vue";
import {mapGetters} from "vuex";

export default {
    data() {
        return {
            baseUrl: process.env.VUE_APP_BASE,
            serviceMenus: [
                'cloud_servers',
                'create_cloud_server',
                'cloud_disks',
                'create_cloud_disk',
                'cloud_networks',
                'create_cloud_network',
                'port_forwardings',
                'firewalls',
                'ssh_key',
                'ssh_terminal',
            ],
            billingMenus: [
                'billing',
            ],
            accountMenus: [
                'manage_account',
            ],
            iamMenus: [
                'cloud_iam',
            ],
          supportRoutes: ['my_inquiry','support_chat']
        }
    },
    created() {
        
    },
    computed: {
      ...mapGetters({
        permission: "auth/permissionList"
      })
    },
    methods: {
        reload(name){
            if(this.$route.name === name){
                this.$store.commit('setReload', true)
            }
        }
    },
    setup() {
        const { can } = useAbility();

        return {
        can
        };
    },
}
</script>
<style scoped>
/* .router-link-exact-active {
  color: #000000;
  font-weight: 600;
}
.d_block{
  display: block;
} */
</style>
import guest from "@/middlewares/guest";
import auth from "@/middlewares/auth";
import {cloudAccountRoutes} from "@/routes/account/cloudAccountRoutes";

let routes = [
  {
    path: '/login',
    component: () => import(/* webpackChunkName: "auth" */ '../views/auth/Login'),
    name: 'login',
    meta: {
      middleware: [
        guest
      ]
    }
  },
  /*{
    path: '/register',
    component: () => import(/!* webpackChunkName: "auth" *!/ '../views/auth/Register'),
    name: 'register',
    meta: {
      middleware: [
        guest
      ]
    }
  },
  {
    path: '/email-verification/:token',
    component: () => import(/!* webpackChunkName: "auth" *!/ '../views/auth/EmailVerification'),
    name: 'email-verification',
    meta: {
      middleware: [
        guest
      ]
    }
  },
  {
    path: '/forgot-password',
    component: () => import(/!* webpackChunkName: "auth" *!/ '../views/auth/ForgotPassword'),
    name: 'forgot-password',
    meta: {
      middleware: [
        guest
      ]
    }
  },
  {
    path: '/reset-password/:token',
    component: () => import(/!* webpackChunkName: "auth" *!/ '../views/auth/ResetPassword'),
    name: 'reset-password',
    meta: {
      middleware: [
        guest
      ]
    }
  },*/
  {
    path: '/',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard'),
    name: 'dashboard',
    meta: {
      middleware: [
        auth
      ]
    }
  },
  {
    path: '/tickets',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/ticket/AllTickets.vue'),
    name: 'all_tickets',
    meta: {
      middleware: [
        auth
      ]
    }
  }
]

routes.push(...cloudAccountRoutes);

export default routes;

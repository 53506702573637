<template>
    <small class="has_error" v-for="(err, index) in errors" :key="key(index)">{{ err }}</small>
</template>

<script>
export default {
    props: ['errors'],
    methods: {
        key(index) {
            return `validation_error_${index}_${Math.random()}`
        }
    }
}
</script>
<style scoped>
    .has_error{
        color: red;
    }
</style>
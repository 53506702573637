import axios from "@/util/axios";

export default {
  namespaced: true,
  state () {
    return {
      servicePermission: []
    }
  },
  mutations: {
    setServicePermission(state, payload) {
      state.servicePermission = payload;
    },
  },
  actions: {
    servicePermissionList({commit}) {
      axios.get('/customer-permission-list').then(({ data }) => {
        commit('setServicePermission', data);
      })
    },
  },
  getters: {
    getServicePermission: (state) => state.servicePermission,
  }
}
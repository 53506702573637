import axios from 'axios'
import Cookies from 'js-cookie'
import router from "../routes/index";
import Swal from 'sweetalert2';
let alertShown = false;
// const customer = JSON.parse(localStorage.getItem('customer'))|| null
const http = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// if (customer)
//   http.defaults.headers.common['Authorization'] = `Bearer ${customer.token}`

if (Cookies.get('auth_token'))
  http.defaults.headers.common['Authorization'] = `Bearer ${Cookies.get('auth_token')}`
  http.interceptors.response.use(
      (response) => {
        // Pass through successful responses
        return response;
      },
      (error) => {
        if (error.response) {
          const { data } = error.response;

          // Check for payable_status = false in the error response
          if (data?.payable_status === false && !alertShown) {
            alertShown = true;

            Swal.fire({
              title: "Payment info not found!",
              text: data.message,
              icon: "warning"
            }).then(() => {
              router.push('/manage-account/payment-cost');
              alertShown = false;
            })
          }
        }

        // Always reject the promise to let the caller handle it if needed
        return Promise.reject(error);
      }
  );
export default http
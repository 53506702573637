import auth from "../../middlewares/auth";

export const cloudAccountRoutes = [
    {
        path: '/manage-account/:slug?',
        component: () => import(/* webpackChunkName: "services" */ '../../views/account/ManageAccountIndex.vue'),
        name: 'manage_account',
        props: true,
        meta: {
            middleware: [
                auth
            ]
        }
    },
]